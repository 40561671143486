module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[],"quality":80},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Crystal","short_name":"Crystal","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/crystal.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0be862c98794b69ca06c147821e5c579"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/ken/projects/ken/thecrystal-website/src/components/layout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"/Users/ken/projects/ken/thecrystal-website/node_modules/gatsby-remark-images","id":"5ac0dc16-1cf0-59e2-afe3-d845fdfd2c4c","name":"gatsby-remark-images","version":"6.11.1","modulePath":"/Users/ken/projects/ken/thecrystal-website/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"quality":100,"maxWidth":1600,"linkImagesToOriginal":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/ken/projects/ken/thecrystal-website","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":100,"maxWidth":1600,"linkImagesToOriginal":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
